.CmsPage {
  .HomePageInfo {
      &-BlockTitle {
        h2 {
          color: white;
          font-size: 1.6rem;
          font-weight: 400;
        }
      }

      &-BlockContent {
        font-size: 1.4rem;
      }
  }
}

.LayoutGroupWidget-Item {
  
  .CmsBlock-Wrapper {
      border: 1px solid #e6e6e6;
      
      .HomePageInfo-BlockContent {
        img {
          height:auto !important;
        }
      }
  }

  &:last-of-type {
    
    .CmsBlock-Wrapper {
      padding: 2rem;

      h1 {
        font-size: 2.5rem;
        margin-bottom: 2rem;
        line-height: 3.5rem;
        font-weight: 400;
      }
    }
  }
}