.ProductActions{
    
    &-AddToCartWrapper {
        flex-wrap: wrap;

        .ProductActions-Schema {
            width:100%;
        }

        &.FixedElement-Bottom {
            .ProductActions-Schema {
                width:auto;
            }
        }
    }
}