:root {
  --banner-item-button-padding: 1rem 2rem !important;
  --banner-item-title-font-size: 2rem !important;
  
}

.BannerItem {
  --banner-item-content-padding: 0 2rem 1rem 2rem;
  --banner-item-link-border: none;

  &-Title {
    text-transform: uppercase !important;
    font-weight: 400;
  }
}