@include desktop {
    .SaasSliderWidget {
        padding: 0 1.5rem 4rem 1.5rem !important;
    }
}

.SaasSlider-Container {
    margin-bottom: 4rem;
}

.SaasSliderWidget {

    &-Heading {
        font-weight: 600;
        font-size: 3rem !important;
        line-height: 5.5rem;
    }

    .ProductCard {

        &-Picture {
            transform: scale(1) !important;
            padding-bottom: 100%;
        }

        &-Price {
            margin-bottom: 1rem;

            span {
                font-size: 2rem;
            }

            .ProductPrice-SubPrice {
                display: none;
            }
        }

        &-Link {
            h2 {
                font-size: 1.8rem;
                font-weight: 300;
                color: #272323;
            }
        }

        &-Qty {
            display: none;
        }

        &-AddToCart {
            flex-grow: 0;
            margin: 0 auto;
            width: 50%;
        }
    }
}