#root {
    --content-wrapper-width: 1400px;
    --secondary-base-color: #B89E53;
    --secondary-light-color: #000;
    --secondary-dark-color: #000;
    --button-background: var(--secondary-base-color);
    --button-border: var(--secondary-base-color);
    --global-link-color: #C4C4C4;
    --footer-column-title-color: #fff;
    --footer-menu-link-color: var(--global-link-color);
    --store-social-list-icon-color: var(--secondary-base-color);
    --newsletter-subscription-button-background: var(--secondary-base-color)
}

.SaasHeader {
    --header-menu-link-font-weight: normal;
    --header-menu-main-item-margin-right-after-tablet: 1.2%;
}

.SearchField {
    --search-bar-max-width-desktop: 220px;
}

.BannerBlock-Container {
    --banner-block-column-2-gap: 3rem;
    --banner-block-column-5-gap: 1rem;
    --banner-block-item-height-col-2: 45rem;
    --banner-block-item-height-col-4: 35rem;
    --banner-block-item-height-col-5: 28rem;
    --banner-block-item-height-col-6: var(--banner-block-item-height-col-5);

    @include mobile {
        --banner-block-item-height-col-5: var(--banner-block-item-height-col-2);
        --banner-block-item-height-col-6: var(--banner-block-item-height-col-2);
      }
    
      @include tablet {
        --banner-block-item-height-col-3: 45rem;
        --banner-block-item-height-col-4: 50rem;
        --banner-block-item-height-col-5: var(--banner-block-item-height-col-3);
        --banner-block-item-height-col-6: var(--banner-block-item-height-col-3);
      }
}

.BannerItem {   
    --banner-item-content-padding: 0 1rem 1rem 1rem;
    --banner-item-link-border: none;
}

.ProductListWidget {
    --product-list-widget-h2-font-size: 2.5rem;
    --product-list-widget-h2-font-style: normal;
    --product-list-widget-h2-font-weight: normal;

    @include tablet {
        --product-list-widget-h2-font-size: 2rem;
    }
}

.Footer {
    --footer-content-background-color: var(--secondary-dark-color);
}