.Header-MinicartItemCount {
    padding:0;
    width:2rem !important;
    height:2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -1rem;
    top: -1rem;
}

.SaasHeader {
    &-MobileUsps {
        display: none;
    }

    &-Switcher {
        display: none;
    }
}
.HeaderMenu {
    &-Aside {
        display: none;
    }
}
.SiteUsp {
    &-Item {
        --site-usp-icon-color: #B89E53;
    }

    &-Content {
        --site-usp-font-size: 1.4rem;
        font-style: normal !important;
        color: var(--global-link-color);
    }
}