:root {
  --banner-block-item-height-col-3: 33.5rem !important;

  @include mobile {
    --banner-block-item-height-col-3: 28rem !important
  }

  @include tablet {
    --banner-block-item-height-col-3: 28rem !important
  }

  --banner-block-gap: 4rem !important;
  --banner-block-gap-small: 1.5rem !important
}

.BannerBlock {
  &-Item {
    &_type_column_5 {
        height: 27rem;
    }
  }
}

.BannerItem {
  &-Title {
    .CmsPage & {
        font-size: 4rem;
    }
    .BannerBlock-Item_type_column_5.BannerItem_isBlock & {
        font-size: 1.5rem;
        text-transform: uppercase;
    }
  }

  &-ContentBlock {
    .BannerBlock-Item_type_column_5.BannerItem_isBlock & {
        width: 100%;
        text-align: center;
    }
  }
}