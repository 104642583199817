:root {
  --banner-slider-height: 54rem !important;
  --banner-slider-item-content-block-margin: 10px auto 0 0 !important;
  --banner-slider-item-content-block-padding: 0 1.5rem 1.5rem 1.5rem;
  --banner-slider-item-title-font-size: 2.8rem !important;
  --banner-item-content-font-size: 1.8rem !important;
  --banner-item-content-line-height: 2.2 !important;

  @include mobile {
    --banner-slider-height: 36rem;
  }
}

.BannerSlider-Item  {
  .BannerItem-Title {
    font-size: var(--banner-slider-item-title-font-size);
    text-transform: none !important;
    font-weight:500 !important;
    margin: 0 !important;
  }

  .BannerItem-ContentBlock {
    max-width: var(--content-wrapper-width) !important;
    margin: auto auto 2rem auto !important;
    display: flex !important;
    flex-direction: column !important;

    .BannerItem-Content {
      font-style: normal !important;
      font-size: 1.4rem;
      line-height: var(--banner-item-content-line-height);
    }
  }
}