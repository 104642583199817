:root {
    --footer-column-content-color:var(--global-link-color) !important;
}
.Footer-ColumnContent{
    color: #C4C4C4 !important;
}
.StoreSocial {
    a {
        color: var(--secondary-base-color);
    }
}